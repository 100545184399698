import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { version } from '../package.json';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return <>
        <Container fluid className="direccion">
            <Row className="bg-green">&nbsp;</Row>
            <Row className="bg-secondary text-white">
                <Container>
                    <Row className="py-4">
                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Montevideo</h5>
                                <h6>SMART CLINIC: <br/>Isabelino Bosch 2529</h6>
                                <a href="mailto:uialergia@gmail.com">
                                    <FontAwesomeIcon icon="envelope" className="mr-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="tel:‭27099624">
                                    <FontAwesomeIcon icon="phone" className="mr-2" style={{width: '1em'}}/>
                                    ‭2709 9624
                                </a>
                                <div className="clearfix" />
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12261.343244830576!2d-56.166504679011034!3d-34.90422349750764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f22febf45c5341a!2sSmart%20Clinic!5e0!3m2!1ses-419!2suy!4v1592768925325!5m2!1ses-419!2suy"
                                       frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" className="mt-3 w-100" title="Mapa Montevideo" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Maldonado</h5>
                                <h6>Centro CORPUS: <br/>Zelmar Michelini 742</h6>
                                <a href="mailto:uialergia@gmail.com">
                                    <FontAwesomeIcon icon="envelope" className="mr-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="tel:093343166">‭
                                    <FontAwesomeIcon icon="phone" className="mr-2" style={{width: '1em'}}/>
                                    093 343 166
                                </a>
                                <div className="clearfix" />
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.838265557294!2d-54.9629234476477!3d-34.91050808120881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95751a9d636221ff%3A0x8fb48cf4ac7d5987!2sZelmar%20Michelini%20742%2C%2020000%20Maldonado%2C%20Departamento%20de%20Maldonado!5e0!3m2!1ses-419!2suy!4v1592768973605!5m2!1ses-419!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" className="mt-3 w-100" title="Mapa Maldonado" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Fray Bentos</h5>
                                <h6>Plaza Hotel: <br/>25 de mayo esquina 18 de julio</h6>
                                <a href="mailto:uialergia@gmail.com" className="py-5">
                                    <FontAwesomeIcon icon="envelope" className="mr-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="https://wa.me/+59898159100">
                                    <FontAwesomeIcon icon={faWhatsapp} className="mr-2" style={{width: '1em'}}/>
                                    098 159 100
                                </a>
                                <div className="clearfix" />
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d835.4298184252639!2d-58.312801170767784!3d-33.116463398804406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a54d88d16a7f3f%3A0xd0440a6c0787c554!2sPlaza%20Hotel%20Fray%20Bentos!5e0!3m2!1sen!2suy!4v1607124159022!5m2!1sen!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" className="mt-3 w-100" title="Mapa Fray Bentos" />

                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between text-center my-4">
                        <Col sm={12}>
                            Copyright © {(new Date()).getFullYear()} Todos los derechos reservados
                            <br/>
                            <small>Version: {version}</small>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    </>
}

export default Footer
import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Banner() {
    return <section className="banner">
        <Container>
            <Row>
                <Col lg={12} className="text m-4">
                    <h1 className="mt-4 mx-3">Bienvenidos<br/>Unidad Integral de Alergia</h1>
                    <h4 className="mx-3">Somos un equipo de profesionales especializados en el estudio y tratamiento de las enfermedades alérgicas.</h4>
                </Col>
            </Row>
        </Container>
    </section>
}

export default Banner
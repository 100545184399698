import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSyncAlt, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {WhatsApp, Email} from './Header';

function Contact() {
    const formRef = useRef();
    const [nombre, setNombre] = useState();
    const [tel, setTel] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (['success','failed'].includes(status)) setTimeout(() => setStatus(null), 5000);
    }, [status])

    const submit = useCallback((evt) => {
        setStatus('sending');
        evt.preventDefault();
        return axios.post(`${process.env.REACT_APP_SERVER_URL}/send`, {nombre, tel, email, mensaje})
            .then(result => {
                if (result.data === 'OK') {
                    setStatus('success');
                } else {
                    setStatus('failed');
                }
                gtag('event', 'conversion', {'send_to': 'AW-1066767018/WDnxCNq32dwBEKql1vwD'});// eslint-disable-line no-undef
            }).catch(error => {
                console.error(error);
                setStatus('failed');
            });
    }, [nombre, tel, email, mensaje])


    return <section className="mt-4 py-2" id="contacto">
            <Container>
                <Row className="justify-content-between align-items-center">
                    <Col lg={5} md={6}>
                        <h1 data-aos="slide-up" className="aos-init aos-animate">
                            Nuestro horario
                        </h1>
                        <ul className="time-list">
                            <li className="d-flex justify-content-between">
                                <span>Lunes - Viernes</span>
                                <span>08:00 - 20:00</span>
                            </li>
                        </ul>
                        <p>Clínica ubicada en zona céntrica de Montevideo con fácil acceso desde cualquier punto de la ciudad y del interior por encontrarse cerca de la Terminal 3 Cruces, con disponibilidad de estacionamiento en las inmediaciones.</p>
                        <ul className="list-unstyled d-flex justify-content-around flex-wrap">
                            <WhatsApp />
                            <Email />
                        </ul>
                    </Col>
                    <Col lg={6} md={6} className="pt-60 pb-60">
                        <form className="contacto" method="post" action={`${process.env.REACT_APP_SERVER_URL}/send`} onSubmit={submit} ref={formRef}>
                            <h3 className="pb-20 text-center mb-20">Contactanos</h3>
                            <input type="text" className="form-control" name="name" placeholder="Nombre" required onChange={(evt) => setNombre(evt.target.value)}/>
                            <input type="text" className="form-control" name="phone" placeholder="Teléfono" required onChange={(evt) => setTel(evt.target.value)}/>
                            <input type="email" className="form-control" name=" email" placeholder="Email" required onChange={(evt) => setEmail(evt.target.value)}/>
                            <textarea name="message" className="form-control" rows="5" placeholder="Mensaje" required onChange={(evt) => setMensaje(evt.target.value)}/>

                            <Button className='text-uppercase' variant={'failed' === status ? 'danger': 'primary'} block type="submit" disabled={status !== null}>
                                {!status && 'enviar'}
                                {'sending' === status && <>Enviando <FontAwesomeIcon spin={true} icon={faSyncAlt}/></>}
                                {'success' === status && <>Enviado <FontAwesomeIcon icon={faCheckCircle}/></>}
                                {'failed' === status && <>Error, favor reintentar <FontAwesomeIcon icon={faTimesCircle}/></>}
                            </Button>
                        </form>
                    </Col>
                </Row>
            </Container>
    </section>
}

export default Contact
import React from 'react';
import Header from './Header';
import Banner from './Banner';
import Offer from './Offer';
import Team from './Team';
import Contact from './Contact';
import Footer from './Footer';


function App() {
  return (<>
          <Header />
          <Banner />
          <Offer />
          <Team />
          <Contact />
          <Footer />
    </>
  );
}

export default App;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Col, Container, Nav, Navbar } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

library.add(faPhone, faEnvelope, faBars);
const UIA = {
    green: '#00a199',
    yellow: '#fae811',
};

function Facebook () {
    return <li>
        <a href="https://www.facebook.com/Unidad-Integral-de-Alergia-105044698001253/" target="_blank" rel="noopener noreferrer" className="mr-2">
            <FontAwesomeIcon icon={faFacebookSquare} color={UIA.green} size="lg"/>
        </a>
    </li>;
}

function Instagram () {
    return <li>
        <a href="https://instagram.com/unidad_integral_de_alergia" target="_blank" rel="noopener noreferrer" className="mr-2">
            <FontAwesomeIcon icon={faInstagram} color={UIA.green} size="lg"/>
        </a>
    </li>;
}

export function WhatsApp () {
    return <li>
        <a href="https://wa.me/+59898159100" className="text-secondary mr-3 font-weight-light overflow-hidden" target="_blank" rel="noopener noreferrer" style={{whiteSpace: 'nowrap'}}>
            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" size="lg" color={UIA.green}/>
            <small>(+598) 098 159 100</small>
        </a>
    </li>;
}

export function Email () {
    return <li>
        <a href="mailto:uialergia@gmail.com" className="text-secondary mr-3 font-weight-light overflow-hidden" style={{whiteSpace: 'nowrap'}}>
            <FontAwesomeIcon icon="envelope" className="mr-2" size="lg" color={UIA.green}/>
            <small>uialergia@gmail.com</small>
        </a>
    </li>;
}

function Header () {
    return <>
        <Navbar id="SocialContact" className="m0">
            <Container>
                <ul className="list-unstyled mb-0 d-flex flex-row flex-wrap">
                    <Facebook/>
                    <Instagram/>
                </ul>
                <ul className="list-unstyled mb-0 d-flex flex-row flex-wrap">
                    <WhatsApp/>
                    <Email/>
                </ul>
            </Container>
        </Navbar>
        <Navbar id="Header" expand="md" className="border-bottom border-top" sticky="top" collapseOnSelect>
            <Container>
                <div className="d-flex flex-nowrap align-items-center w-100 justify-content-between">
                    <Navbar className="Brand px-0 px-lg-3 w-100" href="#inicio">
                        <img src="/images/logo.svg" alt="logo" height="100px" className="mr-2"/>
                        <h2 className="boni uia_green mb-0">
                            UNIDAD INTEGRAL DE ALERGIA
                            <small className="d-none d-sm-block"> NIÑOS Y ADULTOS</small>
                        </h2>
                    </Navbar>
                    <Navbar.Toggle aria-controls="navbar-nav" className="mr-0">
                        <FontAwesomeIcon icon="bars" color={UIA.green}/>
                    </Navbar.Toggle>
                </div>
                <Navbar.Collapse id="navbar-nav w-100 d-block">
                    <Row className="ml-0">
                        <Col lg={12} className="pr-0">
                            <Nav className="center_nav pull-right">
                                <Nav.Item>
                                    <Nav.Link href="#inicio">Inicio</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#equipo">Quienes&nbsp;somos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#servicios">Servicios</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#contacto">Contacto</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>;
}

export default Header;